export const colors = {
  shadyOranje: "#FF7366",
  shadyError: "#EE2355",
  sunYellow: "#FFC96b",
  skyBlue: "#4DACFF",
  charcoal: "#333333",
  black: "#1a1a1a",
  darkestGrey: "#404040",
  tertiaryGrey: "#A6A6A6",
  sixteenGrey: "#CCCCCC",
  slate: "#ededed",
  offWhite: "#FFFAF7",
  white: "#FFFFFF",
  creamOranje: "#FFCBB3",
  raspberryBeret: "#EDBBD6",
  cerulean: "#95DCF2",
};
